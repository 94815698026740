import React from "react";

export const About = () => {
  return (
    <div className={"about-page"}>
      <div className={"about-text"}>
        <h1>I’m a graphic designer and art director from Reykjavík, Iceland. I graduated from IUA (the Iceland
          University of the Arts) in 2020 with a bachelor’s degree in graphic design. In recent years I’ve developed a
          strong passion for typography, type design, digital design, and visual identities. After graduating, I started
          working at a design studio called Kolofon and later moved to a digital agency called Gangverk. Since February
          2023 I’ve been working as an art director at Kolibri.</h1>
        <div style={{ height: "var(--space-3)" }} />
        <h5>Contact</h5>
        <div>
          <a href={"tel:+3547834655"}>+354 783 4655</a>
        </div>
        <div>
          <a href={"mailto:simonvidars@gmail.com"}>simonvidars@gmail.com</a>
        </div>
      </div>
      <div className={"about-photo"}>
        <img src={"https://images.prismic.io/simonv/Ziq6s_Pdc1huK1_M_Portrait%402x.jpg?auto=format,compress"}
             alt={"by Kjartan Hreinsson"} />
      </div>
      <div className={"about-social"}>
        <h5>Instagram</h5>
        <div>
          <a href={"https://www.instagram.com/simonvidarsson"} target={"_blank"} rel="noreferrer">@simonvidarsson</a>
        </div>
        <br />
        <h5>Website</h5>
        <div>
          <span>Adam Vidarsson</span>
        </div>
      </div>
      <div className={"about-credits"}>
        <h5>Photo</h5>
        <div>
        <span>Kjartan Hreinsson</span>
        </div>
      </div>
    </div>
  );
};